// React and ReactDOM imports
import React from 'react';
import ReactDOM from 'react-dom';
import { jwtDecode } from 'jwt-decode';

// Redux Provider import
import { Provider } from 'react-redux';

// ADAL imports
import { runWithAdal } from 'react-adal';
import { authContext } from 'Configuration/AdalConfiguration';

// App and Unauthorized views imports
import App from 'App_CA';
import Unauthorized from 'Views/Unauthorized';

// Store configuration import
import store from 'Configuration/StoreConfiguration';

// Constants import
import { VALID_LOGIN_F2W_ROLES } from 'Constants/F2wRolesConstants';

// Function to render the application
const renderApp = (Component) => {
  ReactDOM.render(<Provider store={store}>{Component}</Provider>, document.getElementById('root'));
};

// Run the application with ADAL authentication
runWithAdal(authContext, async () => {
  try {
    // Get ADAL profile user
    var token = authContext.getCachedToken('6e01c747-cb4c-4a1e-985c-708dc1a8943f');
    var profile = jwtDecode(token);

    // Get roles from ADAL profile user and validate
    const roles = profile.roles;
    const validRoles = roles.filter((role) => VALID_LOGIN_F2W_ROLES.includes(role));

    // If no valid roles, throw an error
    if (validRoles.length === 0) {
      throw new Error('401');
    }

    // Render the main application
    renderApp(<App />);
  } catch (error) {
    // Render the Unauthorized view with the error message
    renderApp(<Unauthorized errorMessage={error.message} />);
  }
});
