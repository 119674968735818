import React, { useEffect } from 'react';
import { Button, Card, Modal, Table, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';

import { DATETIME_FORMAT_WEB } from 'Constants/DateFormatConstants';
import { DateTimeFormater } from 'Handlers/DateTimeHandler';
import RestClient from 'Services/RestClientService';
import { setLoader } from 'Redux/Actions/Loading/LoadingAction';
import { DS_F2W_Business_Travel_Team } from 'Constants/F2wRolesConstants';

const CommentsCard = (props) => {
  const { flyToWorkTicketInfo, setRefreshComponent, refreshComponent } = props;
  const dispatch = useDispatch();

  // State variables
  const [showAddCommentModal, setShowAddCommentModal] = React.useState(false);
  const [comment, setComment] = React.useState('');
  const [commentError, setCommentError] = React.useState('');

  // Get user and roles from Redux store
  const user = useSelector((state) => state.currentUser);
  const userRoles = user?.roles;

  // Handle adding a comment
  const handleInsertComment = (e) => {
    e.preventDefault();
    if (!comment) {
      setCommentError('Comment is required');
      return;
    }

    const payload = {
      text: comment,
      ticketId: flyToWorkTicketInfo.id,
    };

    RestClient.Post('comment/insert', payload)
      .then((response) => {
        if (response.ok) {
          toastr.success('Success', response.message);
        } else {
          toastr.error('Error', response.message);
        }
      })
      .finally(() => {
        setRefreshComponent(Math.random());
        setShowAddCommentModal(false);
        setComment('');
      });
  };

  // Handle removing a comment
  const handleRemoveComment = (commentId) => {
    const payload = {
      flightTicketId: flyToWorkTicketInfo.id,
      commentId: commentId,
    };

    dispatch(setLoader(true));
    RestClient.Delete('comment/delete', payload)
      .then((response) => {
        if (response.ok) {
          toastr.success('Success', response.message);
        } else {
          toastr.error('Error', response.message);
        }
      })
      .finally(() => {
        setRefreshComponent(Math.random());
        dispatch(setLoader(false));
      });
  };

  // Effect to log refreshComponent changes
  useEffect(() => {
    console.log('refreshComponent', refreshComponent);
  }, [refreshComponent]);

  return (
    <>
      <Card style={{ marginTop: '1%' }}>
        <Card.Header>
          <i className="fa fa-pencil mr-2"></i>Comments
        </Card.Header>
        <Card.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Created by</th>
                <th>Group</th>
                <th>Comment</th>
                <th>Timestamp</th>
                {userRoles.includes(DS_F2W_Business_Travel_Team) && <th>Actions</th>}
              </tr>
            </thead>
            <tbody>
              {flyToWorkTicketInfo.comments?.map((comment, index) => (
                <tr key={index}>
                  <td>{comment.createdBy}</td>
                  <td>{comment.group}</td>
                  <td>{comment.text}</td>
                  <td>{DateTimeFormater(comment.created, null, DATETIME_FORMAT_WEB)}</td>
                  {userRoles.includes(DS_F2W_Business_Travel_Team) && (
                    <td>
                      <Button variant="danger" onClick={() => handleRemoveComment(comment.id)}>
                        Remove
                      </Button>
                    </td>
                  )}
                </tr>
              ))}
              {flyToWorkTicketInfo.comments?.length === 0 && (
                <tr>
                  <td colSpan="5" align="center">
                    No results found
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Card.Body>
        <Card.Footer>
          <Button variant="info" size="sm" onClick={() => setShowAddCommentModal(true)}>
            Add comment
          </Button>
        </Card.Footer>
      </Card>

      <Modal
        size="lg"
        show={showAddCommentModal}
        onHide={() => {
          setCommentError(null);
          setShowAddCommentModal(false);
        }}
      >
        <Modal.Body>
          <Form onSubmit={handleInsertComment}>
            <Form.Group controlId="comment">
              <Form.Label>Comment</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={comment}
                onChange={(e) => {
                  setCommentError(null);
                  setComment(e.target.value);
                }}
              />
              {commentError && <Form.Text className="text-danger">{commentError}</Form.Text>}
            </Form.Group>
            <Button variant="primary" type="submit">
              Add
            </Button>
            <Button variant="info" className="ml-2" onClick={() => setShowAddCommentModal(false)}>
              Close
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CommentsCard;
