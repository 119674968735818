import { jwtDecode } from 'jwt-decode';
import { GET_USER_SUCCESS } from '../../../Constants/UserConstants';
import { authContext } from 'Configuration/AdalConfiguration';

export function getUser() {
  function getUserSuccess(user) {
    return {
      type: GET_USER_SUCCESS,
      data: { user },
    };
  }

  return async function (dispatch) {
    var token = authContext.getCachedToken('6e01c747-cb4c-4a1e-985c-708dc1a8943f');
    var profile = jwtDecode(token);
    const user = profile;
    // console.log('user', user);
    dispatch(getUserSuccess(user));
  };
}
