import { AuthenticationContext, adalFetch } from 'react-adal';
import { jwtDecode } from 'jwt-decode';

export const adalConfig = {
  tenant: 'e3e1f65b-b973-440d-b61c-bc895fc98e28',
  clientId: '6e01c747-cb4c-4a1e-985c-708dc1a8943f',
  endpoints: {
    api: '6e01c747-cb4c-4a1e-985c-708dc1a8943f',
  },
  cacheLocation: 'localStorage',
};

export const authContext = new AuthenticationContext(adalConfig);

export const adalApiFetch = async (fetch, url, options) => {
  const token = authContext.getCachedToken(adalConfig.clientId);
  if (token) {
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    const tokenExpirationTime = decodedToken.exp;
    // const expirationDate = new Date(tokenExpirationTime * 1000);

    // console.log('Adal fetch invoked at:', new Date(currentTime * 1000).toLocaleString());
    // console.log('Token expires at:', expirationDate.toLocaleString());

    // Check if the token is about to expire in the next 5 minutes
    if (tokenExpirationTime - currentTime < 300) {
      console.log('Token is about to expire, renewing...');
      try {
        await renewToken();
      } catch (error) {
        console.error('Token renewal failed:', error);
        throw error;
      }
    }
  }

  return adalFetch(authContext, adalConfig.endpoints.api, fetch, url, options);
};

export const renewToken = () => {
  return new Promise((resolve, reject) => {
    console.log('Attempting to renew token...');
    authContext.acquireToken(adalConfig.clientId, (error, token) => {
      if (error || !token) {
        console.error('Token renewal failed:', error);
        reject(error);
      } else {
        console.log('Token renewed successfully');
        resolve(token);
      }
    });
  });
};
