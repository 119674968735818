import React, { useEffect, useState } from 'react';
import { ListGroup } from 'react-bootstrap';
import RestClient from 'Services/RestClientService';
// import { DarkModeContext } from 'Configuration/DarkModeContext';

const F2wSideBar = () => {
  const [ticketCounterList, setTicketCounterList] = useState({
    data: {
      totalAllStatus: 0,
      totalNew: 0,
      totalPendinghr: 0,
      totalPendingbtt: 0,
      totalPendingdes: 0,
      totalConfirmed: 0,
    },
  });

  // const { isDarkMode, toggleDarkMode } = useContext(DarkModeContext);

  useEffect(() => {
    RestClient.Get('flight-ticket/count').then((response) => {
      setTicketCounterList(response);
    });
  }, [window.location.pathname]);

  const checkActiveSideBarLink = (activeLink) => {
    if (window.location.pathname === '/') activeLink = 'new';
    if (window.location.pathname.includes(activeLink)) return 'active';
    return 'inactive';
  };

  const ALL_STATUS = 'all';
  const NEW_STATUS = 'new';
  const PENDING_HR = 'pendinghr';
  const PENDING_BTT = 'pendingbtt';
  const PENDING_DES = 'pendingdes';
  const CONFIRMED = 'confirmed';

  return (
    <React.Fragment>
      <ListGroup variant="flush" className="sidebar">
        <ListGroup.Item className={checkActiveSideBarLink(NEW_STATUS)}>
          <i className="fa fa-user-plus mr-2"></i>
          <a href="/new">
            New <small>({ticketCounterList?.data.totalNew})</small>
          </a>
        </ListGroup.Item>
        <ListGroup.Item className={checkActiveSideBarLink(PENDING_HR)}>
          <i className="fa fa-handshake-o mr-2" />
          <a href="/pendinghr">
            Pending HR <small>({ticketCounterList?.data.totalPendinghr})</small>
          </a>
        </ListGroup.Item>
        <ListGroup.Item className={checkActiveSideBarLink(PENDING_BTT)}>
          <i className="fa fa-exchange mr-2" />
          <a href="/pendingbtt">
            Pending BTT <small>({ticketCounterList?.data.totalPendingbtt})</small>
          </a>
        </ListGroup.Item>
        <ListGroup.Item className={checkActiveSideBarLink(PENDING_DES)}>
          <i className="fa fa-fort-awesome mr-2" />
          <a href="/pendingdes">
            Pending DES <small>({ticketCounterList?.data.totalPendingdes})</small>
          </a>
        </ListGroup.Item>
        <ListGroup.Item className={checkActiveSideBarLink(CONFIRMED)}>
          <i className="fa fa-plane mr-2" />
          <a href="/confirmed">
            Confirmed <small>({ticketCounterList?.data.totalConfirmed})</small>
          </a>
        </ListGroup.Item>
        <ListGroup.Item className={checkActiveSideBarLink(ALL_STATUS)}>
          <i className="fa fa-users mr-2"></i>
          <a href="/all">
            Overview <small>({ticketCounterList.data.totalAllStatus})</small>
          </a>
        </ListGroup.Item>
        <hr />
        {/* <h6 style={{ margin: '5%' }}> Experimental</h6>
        <div style={{ marginLeft: '10%' }}>
          <Button onClick={toggleDarkMode} variant="outline-info">
            {isDarkMode ? 'Light Mode' : 'Dark Mode'}
          </Button>
        </div> */}
      </ListGroup>
    </React.Fragment>
  );
};

export default F2wSideBar;
