export function getEnvironmentVariablesHandler() {
  let result = {};

  if (window.location.hostname.includes('fly2work.tui-dx.com')) {
    result = {
      apiBaseUrl: 'https://tui-prod-prod.apigee.net/corporate/employee/fly2work/',
      apiGpxBaseUrl: 'https://tui-prod-prod.apigee.net/corporate/employee/placement/api/',
      environment: 'prod',
    };
  } else if (window.location.hostname.includes('fly2work.pre.tui-dx.com')) {
    result = {
      apiBaseUrl: 'https://tui-nonprod-pre-prod.apigee.net/corporate/employee/fly2work/',
      apiGpxBaseUrl: 'https://tui-nonprod-pre-prod.apigee.net/corporate/employee/placement/api/',
      environment: 'pre',
    };
  } else if (window.location.hostname.includes('fly2work-web.test.tui-dx.com')) {
    result = {
      apiBaseUrl: 'https://tui-nonprod-test.apigee.net/corporate/employee/fly2work/',
      apiGpxBaseUrl: 'https://tui-nonprod-test.apigee.net/corporate/employee/placement/api/',
      environment: 'test',
    };
  } else if (window.location.hostname.includes('localhost')) {
    result = {
      apiBaseUrl: 'http://localhost:5010/',
      //apiGpxBaseUrl: 'http://localhost:5000/api/',
      apiGpxBaseUrl: 'https://tui-nonprod-test.apigee.net/corporate/employee/placement/api/',
      environment: 'localhost',
    };
  } else {
    result = {
      apiBaseUrl: 'unknown',
      apiGpxBaseUrl: 'unknown',
      environment: 'unknown',
    };
  }
  result.version = '2025.0121.093640';
  return result;
}
