// React and third-party imports
import React from 'react';
import { Card, Form, Col } from 'react-bootstrap';
import Datetime from 'react-datetime';

// Constants and handlers
import { DATE_FORMAT_WEB, DATETIME_FORMAT_WEB } from 'Constants/DateFormatConstants';
import { DateTimeFormater } from 'Handlers/DateTimeHandler';

const RequestInformationCard = (props) => {
  const { flyToWorkTicketInfo } = props;

  return (
    <Card className="staff-profile-card">
      <Card.Header>
        <i className="fa fa-phone mr-2"></i> Request information
      </Card.Header>
      <Card.Body>
        <Form>
          <Form.Row>
            {/* Requested By Name */}
            <Form.Group as={Col}>
              <Form.Label>Requested By Name</Form.Label>
              <Form.Control type="text" defaultValue={flyToWorkTicketInfo.requestedBy?.name} readOnly />
            </Form.Group>
            {/* Requested By Email */}
            <Form.Group as={Col}>
              <Form.Label>Requested By Email</Form.Label>
              <Form.Control type="text" defaultValue={flyToWorkTicketInfo.requestedBy?.email} readOnly />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            {/* Date Requested */}
            <Form.Group as={Col}>
              <Form.Label>Date Requested</Form.Label>
              <Datetime
                timeFormat={false}
                dateFormat={DATE_FORMAT_WEB}
                closeOnSelect
                utc={true}
                inputProps={{ placeholder: DATE_FORMAT_WEB, disabled: true }}
                value={DateTimeFormater(flyToWorkTicketInfo.dateRequested, null, DATE_FORMAT_WEB)}
              />
            </Form.Group>
            {/* Date Created */}
            <Form.Group as={Col}>
              <Form.Label>Date Created</Form.Label>
              <Datetime
                timeFormat={false}
                dateFormat={DATE_FORMAT_WEB}
                closeOnSelect
                utc={true}
                inputProps={{ placeholder: DATE_FORMAT_WEB, disabled: true }}
                value={DateTimeFormater(flyToWorkTicketInfo.created, null, DATETIME_FORMAT_WEB)}
              />
            </Form.Group>
          </Form.Row>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default RequestInformationCard;
