import React, { useState, useRef, useEffect, useContext } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { useDispatch, useSelector } from 'react-redux';

import RestClient from 'Services/RestClientService';
import { setLoader } from 'Redux/Actions/Loading/LoadingAction';
import { DATE_FORMAT_WEB } from 'Constants/DateFormatConstants';
import { DateTimeFormater } from 'Handlers/DateTimeHandler';
import { handleDeleteFlightTicket } from 'Handlers/GlobalFunctions';
import { DS_F2W_Business_Travel_Team } from 'Constants/F2wRolesConstants';
import { DarkModeContext } from 'Configuration/DarkModeContext';

const StatusConfirmedAgGridTable = () => {
  const gridRef = useRef(null);
  const dispatch = useDispatch();

  const { isDarkMode } = useContext(DarkModeContext);

  const user = useSelector((state) => state.currentUser);
  const userRoles = user?.roles;

  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: 'Status',
      field: 'status',
      filter: 'agTextColumnFilter',
      filterParams: { apply: true, newRowsAction: 'keep' },
      floatingFilterComponentParams: { suppressFilterButton: true },
      sortable: true,
      width: 150,
    },
    {
      headerName: 'First Name',
      field: 'firstName',
      filter: 'agTextColumnFilter',
      filterParams: { apply: true, newRowsAction: 'keep' },
      floatingFilterComponentParams: { suppressFilterButton: true },
      sortable: true,
      width: 150,
    },
    {
      headerName: 'Last Name',
      field: 'lastName',
      filter: 'agTextColumnFilter',
      filterParams: { apply: true, newRowsAction: 'keep' },
      floatingFilterComponentParams: { suppressFilterButton: true },
      sortable: true,
      width: 150,
    },
    {
      headerName: 'Destination',
      field: 'destination',
      filter: 'agTextColumnFilter',
      filterParams: { apply: true, newRowsAction: 'keep' },
      floatingFilterComponentParams: { suppressFilterButton: true },
      sortable: true,
      width: 150,
    },
    {
      headerName: 'Country',
      field: 'country',
      filter: 'agTextColumnFilter',
      filterParams: { apply: true, newRowsAction: 'keep' },
      floatingFilterComponentParams: { suppressFilterButton: true },
      sortable: true,
      width: 150,
    },
    {
      headerName: 'Season',
      field: 'season',
      filter: 'agTextColumnFilter',
      filterParams: { apply: true, newRowsAction: 'keep' },
      floatingFilterComponentParams: { suppressFilterButton: true },
      sortable: true,
      width: 150,
    },
    {
      headerName: 'Source Market (staff)',
      field: 'staffSourceMarket',
      filter: 'agTextColumnFilter',
      filterParams: { apply: true, newRowsAction: 'keep' },
      floatingFilterComponentParams: { suppressFilterButton: true },
      sortable: true,
      width: 150,
    },
    {
      headerName: 'Confirmed flight date',
      field: 'confirmedFlightDate',
      filter: 'agDateColumnFilter',
      filterParams: { apply: true, newRowsAction: 'keep' },
      floatingFilterComponentParams: { suppressFilterButton: true },
      sortable: true,
      width: 150,
      valueFormatter: (params) => {
        if (params.data) {
          return DateTimeFormater(
            params.data.flights[params.data.flights.length - 1]?.confirmedFlightDate,
            null,
            DATE_FORMAT_WEB
          );
        }
      },
    },
    {
      headerName: 'Planned assignment date',
      field: 'plannedAssignmentStartDate',
      filter: 'agDateColumnFilter',
      filterParams: { apply: true, newRowsAction: 'keep' },
      floatingFilterComponentParams: { suppressFilterButton: true },
      sortable: true,
      width: 150,
      valueFormatter: (params) => {
        if (params.value) {
          return DateTimeFormater(params.value, null, DATE_FORMAT_WEB);
        }
        return '';
      },
    },
    {
      headerName: 'Type of flight',
      field: 'typeOfFlight',
      filter: 'agTextColumnFilter',
      filterParams: { apply: true, newRowsAction: 'keep' },
      floatingFilterComponentParams: { suppressFilterButton: true },
      width: 150,
    },
    {
      headerName: 'Actions',
      cellRenderer: (params) => {
        const button = document.createElement('button');
        button.classList.add('btn', 'btn-danger', 'btn-sm');
        button.style = 'margin: 0 5px;padding:0.5% 1%;border-radius: 5px;cursor: pointer; font-size: 0.8rem;';
        button.textContent = 'Delete';
        button.addEventListener('click', () =>
          handleDeleteFlightTicket(params.data.id, gridRef, createServerSideDatasource)
        );
        return button;
      },
    },
  ]);

  useEffect(() => {
    if (userRoles.length > 0 && !userRoles.includes(DS_F2W_Business_Travel_Team)) {
      setColumnDefs((prevColumnDefs) => prevColumnDefs.slice(0, -1));
    }
    if (gridRef.current) {
      gridRef.current.api.setColumnDefs(columnDefs);
    }
  }, [userRoles]);

  const createServerSideDatasource = () => {
    dispatch(setLoader(true));
    return {
      getRows: (params) => {
        RestClient.Post('staff/agGridTable/All', params.request)
          .then((response) => {
            if (response) {
              if (response.totalRows === 0) {
                gridRef.current.api.showNoRowsOverlay();
              }
              params.successCallback(response.rows, response.rowCount);
            } else {
              params.failCallback();
            }
          })
          .finally(() => {
            dispatch(setLoader(false));
          });
      },
    };
  };

  const onGridReady = (params) => {
    params.api.setServerSideDatasource(createServerSideDatasource());
    params.api.sizeColumnsToFit();
  };

  const onRowDoubleClicked = (event) => {
    const url = `${location.protocol}//${location.host}/${event.data.status}/${event.data.id}`;
    window.open(url, '_blank');
  };

  const getRowStyle = (params) => {
    if (params.data?.confirmedStatus?.toLowerCase() === 'modified') {
      return { background: isDarkMode ? '#5a4a3a' : '#fcdab6' };
    }
    if (params.data?.confirmedStatus?.toLowerCase() === 'cancelled') {
      return { background: isDarkMode ? '#5a3a3a' : '#fcaeae' };
    }
    if (params.data?.status?.toLowerCase() === 'confirmed') {
      return { background: isDarkMode ? '#3a5a4a' : '#c9f2d6' };
    }

    return { background: isDarkMode ? '#2e2e2e' : '' };
  };

  return (
    <React.Fragment>
      <div style={{ width: '100%', height: '100%' }}>
        <div className="ag-theme-fresh" style={{ height: '700px', width: '100%', padding: '1% 0' }}>
          <AgGridReact
            ref={gridRef}
            columnDefs={columnDefs}
            rowModelType="serverSide"
            onGridReady={onGridReady}
            pagination={true}
            paginationPageSize={100}
            cacheBlockSize={100}
            maxBlocksInCache={1}
            floatingFilter={true}
            animateRows={true}
            columnHoverHighlight={true}
            debug={false}
            onRowDoubleClicked={onRowDoubleClicked}
            getRowStyle={getRowStyle}
          ></AgGridReact>
        </div>
      </div>
    </React.Fragment>
  );
};

export default StatusConfirmedAgGridTable;
