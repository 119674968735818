import React from 'react';
import { Button, Card, Table, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { toastr } from 'react-redux-toastr';

import { DATETIME_FORMAT_WEB } from 'Constants/DateFormatConstants';
import { DateTimeFormater } from 'Handlers/DateTimeHandler';
import RestClient from 'Services/RestClientService';
import { setLoader } from 'Redux/Actions/Loading/LoadingAction';

const AttachementCard = (props) => {
  const { flyToWorkTicketInfo, setRefreshComponent } = props;
  const dispatch = useDispatch();
  const hiddenFileInput = React.useRef(null);

  // Handle file download
  const handleDownloadAttachement = (attachmentId, attachementName) => {
    const payload = {
      id: flyToWorkTicketInfo.id,
      attachmentId: attachmentId,
    };
    RestClient.Download('attachment/download', payload, attachementName);
  };

  // Handle file deletion
  const handleDeleteAttachement = (attachmentId) => {
    const payload = {
      id: flyToWorkTicketInfo.id,
      attachmentId: attachmentId,
    };

    dispatch(setLoader(true));
    RestClient.Post('attachment/delete', payload)
      .then((response) => {
        if (!response) return;
        if (response.ok) {
          toastr.success('Success', response.message);
        } else {
          toastr.error('Error', response.message);
        }
      })
      .finally(() => {
        dispatch(setLoader(false));
        setRefreshComponent(Math.random());
      });
  };

  // Trigger file input click
  const handleOnClickUploadFile = () => {
    hiddenFileInput.current.click();
  };

  // Handle file upload
  const handleUploadFile = (file) => {
    if (!file) return;
    const formValues = [
      {
        key: 'flightTicketId',
        value: flyToWorkTicketInfo.id,
      },
    ];

    dispatch(setLoader(true));
    RestClient.Upload('attachment/upload', file, formValues)
      .then((response) => {
        if (response.ok) {
          toastr.success('Success', 'File uploaded successfully');
        } else {
          toastr.error('Error', 'Error while uploading file');
        }
      })
      .finally(() => {
        dispatch(setLoader(false));
        hiddenFileInput.current.value = null;
        setRefreshComponent(Math.random());
      });
  };

  return (
    <>
      <Card style={{ marginTop: '1%' }}>
        <Card.Header>
          <i className="fa fa-file mr-2"></i>Attachements
        </Card.Header>
        <Card.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Name</th>
                <th>Uploaded by</th>
                <th>Created date</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {flyToWorkTicketInfo?.attachments?.map((attachement, index) => (
                <tr key={index}>
                  <td>{attachement.name}</td>
                  <td>{attachement.createdBy}</td>
                  <td>{DateTimeFormater(attachement.created, null, DATETIME_FORMAT_WEB)}</td>
                  <td>
                    <Button
                      variant="success"
                      size="sm"
                      onClick={() => handleDownloadAttachement(attachement.id, attachement.name)}
                    >
                      Download
                    </Button>
                    <Button
                      variant="danger"
                      size="sm"
                      className="ml-2"
                      onClick={() => handleDeleteAttachement(attachement.id)}
                    >
                      Remove
                    </Button>
                  </td>
                </tr>
              ))}
              {!flyToWorkTicketInfo?.attachments && (
                <tr>
                  <td colSpan="5" align="center">
                    No attachements found
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Card.Body>
        <Card.Footer>
          <Row style={{ display: 'flex' }}>
            <Button onClick={handleOnClickUploadFile} variant="warning" style={{ margin: '0 5px' }} size="sm">
              Upload file
            </Button>
            <input
              ref={hiddenFileInput}
              onChange={(e) => handleUploadFile(e.target.files[0])}
              type="file"
              name="file"
              id="file"
              hidden
            />
          </Row>
        </Card.Footer>
      </Card>
    </>
  );
};

export default AttachementCard;
